<template>
	<el-container class="friend-page">
		<el-aside width="260px" class="friend-list-box">
			<div class="friend-list-header">
				<el-input class="search-text" size="small" placeholder="搜索" v-model="searchText">
					<i class="el-icon-search el-input__icon" slot="prefix"> </i>
				</el-input>
				<el-button plain class="add-btn" icon="el-icon-plus" title="添加好友"
					@click="onShowAddFriend()"></el-button>
				<add-friend :dialogVisible="showAddFriend" @close="onCloseAddFriend"></add-friend>
			</div>
			<el-scrollbar class="friend-list-items">
				<div v-for="(friend, index) in $store.state.friendStore.friends" :key="index">
					<friend-item v-show="friend.showNickName.includes(searchText)" :index="index"
						:active="friend === $store.state.friendStore.activeFriend" @chat="onSendMessage(friend)"
						@delete="onDelItem(friend, index)" @click.native="onActiveItem(friend, index)">
					</friend-item>
				</div>
			</el-scrollbar>
		</el-aside>
		<el-container class="friend-box">
			<div class="friend-header" v-show="userInfo.id">
				{{ userInfo.nickName }}
			</div>
			<div v-show="userInfo.id">
				<div class="friend-detail">
					<head-image :size="160" :name="userInfo.nickName" :url="userInfo.headImage"
						@click.native="showFullImage()"></head-image>
					<div>
						<div class="info-item">
							<el-descriptions title="好友信息" class="description" :column="1">
								<el-descriptions-item label="用户名">{{ userInfo.userName }}
									<el-tag v-if="userInfo.status == 1" size="mini" type="danger">已注销</el-tag>
								</el-descriptions-item>
								<el-descriptions-item label="用户昵称">{{ userInfo.nickName }}
								</el-descriptions-item>
								<el-descriptions-item v-if="isFriend" class="remark" label="备注"
									:contentStyle="friend.remarkNickName ? '' : 'color:#888C;'">{{ friend.showNickName }}
									<span class="icon iconfont icon-modify" @click="onEditRemark"></span>
								</el-descriptions-item>
								<el-descriptions-item label="性别">{{ userInfo.sex == 0 ? "男" : "女"
									}}</el-descriptions-item>
								<el-descriptions-item label="签名">{{ userInfo.signature }}</el-descriptions-item>
							</el-descriptions>
						</div>
						<div class="frient-btn-group">
							<el-button v-show="isFriend" icon="el-icon-position" type="primary"
								@click="onSendMessage()">发消息</el-button>
							<el-button v-show="!isFriend" icon="el-icon-plus" type="primary"
								@click="onAddFriend(userInfo)">加为好友</el-button>
							<el-button v-show="isFriend" icon="el-icon-delete" type="danger"
								@click="onDelItem(userInfo, activeIdx)">删除好友</el-button>
						</div>
					</div>
				</div>
				<!--				<el-divider content-position="center"></el-divider>-->

			</div>
		</el-container>
	</el-container>
</template>

<script>
import FriendItem from "../components/friend/FriendItem.vue";
import AddFriend from "../components/friend/AddFriend.vue";
import HeadImage from "../components/common/HeadImage.vue";

export default {
	name: "friend",
	components: {
		FriendItem,
		AddFriend,
		HeadImage

	},
	data() {
		return {
			searchText: "",
			showAddFriend: false,
			activeIdx: -1,
			friend: {},
			userInfo: {}
		}
	},
	methods: {
		onShowAddFriend() {
			this.showAddFriend = true;
		},
		onCloseAddFriend() {
			this.showAddFriend = false;
		},
		onActiveItem(friend, idx) {
			this.$store.commit("activeFriend", idx);
			this.activeIdx = idx;
			this.friend = friend;
			this.loadUserInfo(friend.id);
		},
		onDelItem(friend, idx) {
			this.$confirm(`确认删除'${friend.nickName}',并清空聊天记录吗?`, '确认解除?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: `/friend/delete/${friend.id}`,
					method: 'delete'
				}).then(() => {
					this.$message.success("删除好友成功");
					this.$store.commit("removeFriend", idx);
					this.$store.commit("removePrivateChat", friend.id);
				})
			})
		},
		onAddFriend(user) {
			this.$http({
				url: "/friend/add",
				method: "post",
				params: {
					friendId: user.id
				}
			}).then(() => {
				this.$message.success("添加成功，对方已成为您的好友");
				let friend = {
					id: user.id,
					nickName: user.nickName,
					showNickName: user.nickName,
					headImage: user.headImage,
					online: user.online
				}
				this.$store.commit("addFriend", friend);
			})
		},
		onSendMessage() {
			let chat = {
				type: 'PRIVATE',
				targetId: this.friend.id,
				showName: this.friend.showNickName,
				headImage: this.friend.headImage,
			};
			this.$store.commit("openChat", chat);
			this.$store.commit("activeChat", 0);
			this.$router.push("/home/chat");
		},
		onEditRemark() {
			this.$prompt('请输入好友备注', '修改备注', {
				inputValue: this.friend.showNickName,
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then((o) => {
				let data = {
					friendId: this.friend.id,
					remarkNickName: o.value
				}
				this.$http({
					url: '/friend/update/remark',
					method: 'put',
					data: data
				}).then((friend) => {
					this.friend = friend;
					this.$store.commit("updateChatFromFriend", friend);
					this.$store.commit("updateFriend", friend);
				})
			})
		},
		showFullImage() {
			if (this.userInfo.headImage) {
				this.$store.commit('showFullImageBox', this.userInfo.headImage);
			}
		},
		updateFriendInfo() {
			if (this.isFriend) {
				// store的数据不能直接修改，深拷贝一份store的数据
				let friend = JSON.parse(JSON.stringify(this.friend));
				friend.headImage = this.userInfo.headImageThumb;
				friend.nickName = this.userInfo.nickName;
				friend.showNickName = friend.remarkNickName ? friend.remarkNickName : friend.nickName;
				this.$store.commit("updateChatFromFriend", friend);
				this.$store.commit("updateFriend", friend);
			}
		},
		loadUserInfo(id) {
			// 获取好友用户信息
			this.$http({
				url: `/user/find/${id}`,
				method: 'GET'
			}).then((userInfo) => {
				this.userInfo = userInfo;
				this.updateFriendInfo();
			})
		}
	},
	computed: {
		friendStore() {
			return this.$store.state.friendStore;
		},
		isFriend() {
			let friends = this.$store.state.friendStore.friends;
			return friends.some((f) => f.id == this.userInfo.id)
		}
	}
}
</script>

<style lang="scss">
.friend-page {
	.friend-list-box {
		display: flex;
		flex-direction: column;
		background: var(--im-background);

		.friend-list-header {
			height: 50px;
			display: flex;
			align-items: center;
			padding: 0 8px;

			.add-btn {
				padding: 5px !important;
				margin: 5px;
				font-size: 16px;
				border-radius: 50%;
			}
		}

		.friend-list-items {
			flex: 1;
		}
	}

	.friend-box {
		display: flex;
		flex-direction: column;

		.friend-header {
			height: 50px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 12px;
			font-size: var(--im-font-size-larger);
			border-bottom: var(--im-border);
			box-sizing: border-box;
		}

		.friend-detail {
			display: flex;
			padding: 50px 80px 20px 80px;
			text-align: center;

			.info-item {
				margin-left: 20px;
				background-color: #ffffff;
				border: 1px #ddd solid;
			}

			.description {
				padding: 20px 20px 0 20px;

				.el-descriptions-item__content {
					line-height: 21px;
				}

				.icon-modify {
					margin-left: 5px;
					cursor: pointer;
					color: var(--im-color-primary)
				}
			}
		}

		.frient-btn-group {
			text-align: left !important;
			padding: 20px;
		}
	}
}
</style>